import React from "react";
import { withStyles } from "@material-ui/core";
import misyonImg from "../../../images/misyon.png";
import askiImg from "../../../images/surdur/aski.jpg";
import bagImg from "../../../images/surdur/bag.jpg";
import boyunlukImg from "../../../images/surdur/boyunluk.png";
import bpaImg from "../../../images/surdur/bpa.jpg";
import codesignImg from "../../../images/surdur/codesign.jpg";
import ecoLabelImg from "../../../images/surdur/ecolabel.png";
import sancoImg from "../../../images/surdur/sanco60.png";
import TwoColumn from "../../../components/home/TwoColumn";
import ManifestCenter from "../../../components/home/ManifestCenter";
import SubPageBase from "../../../components/SubPageBase";

const styles = (theme) => ({
  root: {
    fontSize: "1.5em"
  },
  content: {
    backgroundColor: "#EFF2F7",
    paddingBottom: "10em",

    "& div:first-child": {
      marginBottom: "2em"
    },

  }
});

const IndexPage = ({ classes }) => (
  <SubPageBase title={"ÇEVRESEL ETKİYİ AZALTMA"} img={misyonImg} className={classes.root} objectPosition="top">
    <div className={classes.content}>
      <ManifestCenter
        header="Çevresel etkimizi azaltıyoruz."
        text={
          <>
            <p>Amacımız büyük veya küçük demeden her alanda çevreye etkimizi azaltmak.</p>
            <p>Bu sayfada bulacağın aksiyonlarımız bu sorumluluğumuzun bir parçası. Ancak mükemmel olmaktan çok uzak
              olduğumuzun
              ve bazı cevapları henüz bulamadığımızın farkındayız. Etkilerimizi gerçekten azaltmak ve oyun alanlarımızı
              gerçekten korumak için daha da ileri ve çok daha hızlı gitmeyi görevimiz haline getiriyoruz.</p>

          </>
        }
      />

      <TwoColumn
        imageSrc={codesignImg}
        header="Eko Tasarım"
        text={
          <p>Sera gazı salımımızın büyük çoğunluğu ürünlerimizin üretiminden kaynaklandığından, etkimizi azaltmak için
            öncelikli olarak ürünlerimize odaklanmaya karar verdik. Eko tasarım yaklaşımımızı geliştirerek, ürünlerin
            çevresel
            etkisini azaltırken teknik ve verimli ürünler hayal ediyoruz. Eko tasarım şu anda ürün gamımızın yalnızca
            küçük
            bir bölümünü oluşturuyor, ancak 2026 yılına kadar Decathlon ürünlerinin %100'ünün bir eko tasarım
            yaklaşımından
            yararlanmasını sağlamayı taahhüt ediyoruz.<br />
            <a href="https://www.decathlon.com.tr/eko-tasarim.html">Daha fazlasını keşfet</a>
          </p>
        }
        textFirst={true}
      />

      <TwoColumn
        imageSrc={sancoImg}
        header="%100 Yenilenebilir Enerji"
        text={
          <p>
            Enerji tüketimimizi azaltmak ve yenilenebilir tedarik kaynakları geliştirmek için harekete geçiyoruz.
            Türkiye'deki mağazalarımızın %92'sinde LED aydınlatmaları ve otomasyon sistemi ile tasarruf sağlıyoruz.
            Ayrıca
            2021'den itibaren tüm mağazalarımızın enerjisini yenilenebilir kaynaklardan elde ediyoruz.
          </p>
        }
      />

      <TwoColumn
        imageSrc={askiImg}
        header="Askı Geri Dönüşümü"
        text={
          <p>
            Mağazada kullandığımız ve ürünlerimizi sergilememize yardımcı olan plastik askıları kasalarımızda biriktirip
            geri
            dönüşüme yolluyoruz. Tekrar kullanılabilir durumda olanları tekrar kullanıyor, geri kalanını dönüştürerek
            yeni
            askılarımız için ham madde sağlamış oluyoruz. 2021'in ilk çeyreğinde askılarımızın %75'ini toplayıp geri
            dönüşüm
            ve tekrar kullanım için ayrıştırdık.
          </p>
        }
        textFirst={true}
      />


      <TwoColumn
        imageSrc={bagImg}
        header="İhtiyaç Fazlası Kumaşlarla İleri Dönüşüm"
        text={
          <p>
            Fabrikalarımızdaki ihtiyaç fazlası kumaşları değerlendirmek için katlanır alışveriş çantaları üretiyoruz. Bu
            sayede deniz şortlarından artan kumaşlar çantalarımızdan artan kayışlarla buluşuyor ve atık olmaktan
            çıkıyor.
          </p>
        }
      />

      <TwoColumn
        imageSrc={boyunlukImg}
        header="Boyunluklar Fidan Oluyor"
        text={
          <p>
            Büyük Demirkazık Boyunluk'larımız Türkiye'de tasarlandı ve Türkiye'de üretiliyor. Tırmanış sırasında
            kullanım
            için tasarlanan bu boyunluklar aynı zamanda Ege Orman Vakfı tarafından dikilen fidanlarla ufak bir ormana
            dönüşüyor.
          </p>
        }
        textFirst
      />

      <TwoColumn
        imageSrc={bpaImg}
        header="BPA'sız Kasa Fişleri"
        text={
          <p>
            Mağaza kasalarımızda kullandığımız fişlerimizde artık BPA yok! BPA kağıda eklenerek daha uzun süre
            dayanmasını
            sağlıyor, ancak aynı zamanda bu kağıtların geri dönüştürülmesinde bir engel. Bu yüzden kasa fişlerimizde
            BPA'sız
            kağıtları tercih ediyoruz.
          </p>
        }
      />

      <TwoColumn
        imageSrc={ecoLabelImg}
        header="Biyoçözünür Temizlik Malzemeleri"
        text={
          <p>
            Zararlı kimyasalların sularımızı ve mağazamızı kirletmesini istemiyoruz. Bu sebeple, mağazalarımızı
            Ecolabel
            sertifikalı biyoçözünür temizlik malzemeleriyle temizliyoruz.
          </p>
        }
        textFirst
      />
    </div>
  </SubPageBase>
);

export default withStyles(styles)(IndexPage);
